import React from "react";

import { useTranslation } from "react-i18next";
import WidgetHeader from "../UI/WidgetHeader";
import { withTheme } from "@material-ui/core";
import DonutChart from "../UI/DonutChart";
import { RowItem } from "../UI/RowItem";
import { ICommonCallQulaityTestProps } from "../../CallQualityTest";
import { formatNumber, getColorValue } from "../../../../../helpers/utils";
import ErrorState from "../UI/ErrorState";
import { ITestsResult } from "twillio-tests/core/TestResults";

interface IDefaultProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
  testResult?: ITestsResult;
}

const Combined = (props: IDefaultProps) => {
  const { theme, testCall, config, mainColor, currentThresholds, testResult } = props;
  const { avgJit, avgJitOut, avgPl, avgOutPl, avgRtt, mark, error, mosInbound, transportProtocol } =
    testCall;
  const codec = testCall.codec || (config as any).CallQuality?.codec;

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const mosCombined = mark && mosInbound ? (mark + mosInbound) / 2 : mark ? mark : mosInbound;

  const mosStatus =
    mosCombined > 4
      ? "Excellent"
      : mosCombined > 3
      ? "Good"
      : mosCombined > 2
      ? "Fair"
      : mosCombined > 1
      ? "Poor"
      : "Bad";

  const titleCodec = codec === "G711" ? "G.711" : "";
  const packetLoss = avgPl > avgOutPl ? avgPl : avgOutPl;
  const jitter = avgJit > avgJitOut ? avgJit : avgJitOut;

  const { t } = useTranslation(["common", "tests"]);
  return (
    <>
      <WidgetHeader
        title={t("tests:call-quality.title", { codec: titleCodec ? " - " + titleCodec : "" })}
        tooltip={t("tests:call-quality.tooltip")}
        error={error}
        knowledgeUrl={props.knowledgeUrl}
        estimateTime={props.estimateTime}
        badge={transportProtocol}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <div className="widget-callquality-onecolumn">
          <div className="widget-callquality-chart">
            <DonutChart
              value={mosCombined ? mosCombined.toFixed(1) : "N/A"}
              status={mosStatus}
              parentId="CQC-r-MOSScore"
              childId="CQC-c-mosValue"
            />
          </div>
          <div className="widget-callquality-rows">
            <RowItem
              title="Round trip"
              unit="ms"
              value={avgRtt}
              color={getColor(avgRtt, currentThresholds?.avgRtt)}
              parentId="CQC-r-RoundTrip"
              childId="CQC-c-avgRtt"
            />
            <RowItem
              title="Packet loss"
              value={packetLoss}
              unit="%"
              color={getColor(packetLoss, currentThresholds?.avgPl)}
              parentId="CQC-r-packetLoss"
              childId="CQC-c-avgPl"
            />
            <RowItem
              title="Jitter"
              value={jitter}
              unit="ms"
              color={getColor(jitter, currentThresholds?.avgJit)}
              parentId="CQC-r-jitter"
              childId="CQC-c-avgJit"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withTheme()(Combined);
