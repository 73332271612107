import React from "react";
import classNames from "classnames";
import "./Widget.css";

interface RowItemProps {
  title: string;
  small?: boolean;
  value?: string | number;
  unit?: string;
  status?: string;
  color?: string;
  formatting?(value: number): string;
  parentId?: string;
  childId?: string;
}

export const RowItem = ({
  small = false,
  title,
  value,
  unit,
  status,
  formatting,
  color,
  parentId,
  childId,
}: RowItemProps) => {
  const isInvalidNumber = typeof value === "number" && (!Number.isFinite(value) || value === -1);

  let displayValue;
  if (value === undefined || value === null || isInvalidNumber) {
    displayValue = isInvalidNumber && value !== undefined && value < 0 ? "N/A" : "-";
  } else if (formatting && typeof value === "number") {
    displayValue = formatting(value);
  } else if (value === "error") {
    displayValue = "X";
  } else {
    displayValue = value;
  }

  return (
    <div style={{ padding: `${small ? "4px" : "8px"} 0px` }} id={parentId}>
      <div className="rowItem-container">
        <p className="rowItem-title">{title}</p>
        <p className={classNames(status ? "rowItem-status-true" : "rowItem-status-false")}>
          <>
            <span
              className={classNames(
                "rowItem-value",
                displayValue === "N/A" ? "rowItem-value-NA" : "rowItem-value-noNa"
              )}
              id={childId}
              style={{ color }}
            >
              {displayValue}
            </span>
            {unit && displayValue !== "N/A" && displayValue !== "-" && (
              <span className="rowItem-unit">{unit}</span>
            )}
          </>
          {status && typeof status === "string" && value && (
            <span className="rowItem-status">{status}</span>
          )}
        </p>
      </div>
    </div>
  );
};
