import { withTheme } from "@material-ui/core";
import React from "react";
import { ITestsResult, ITurnTest } from "twillio-tests/core/TestResults";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import WidgetHeader from "./UI/WidgetHeader";
import { ThumbUpIcon } from "./UI/Icons";
import { ErrorIcon } from "./UI/Icons";
import ErrorState from "./UI/ErrorState";

interface ITurnConnectivityTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config?: TestConfiguration;
}

const initialValues = {
  tcpConnectionTime: undefined,
  udpConnectionTime: undefined,
  tlsConnectionTime: undefined,
  tcpGatheringTime: undefined,
  udpGatheringTime: undefined,
  tlsGatheringTime: undefined,
  behindProxy: undefined,
};

const NewTurnConnectivityTest = (props: ITurnConnectivityTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    testName,
    config,
  } = props;

  let disableProtocols = config?.TurnConnectivity?.disableProtocols;
  let widget = config?.TurnConnectivity?.widget;
  let isWssEnabled = config?.TurnConnectivity?.enableWss;

  let disableProtocols2 = config?.TurnConnectivity2?.disableProtocols;
  const turnTest: ITurnTest =
    testResult && testResult.turnTest ? testResult.turnTest : initialValues;
  const turnTest2: ITurnTest =
    testResult && testResult.turnTest2 ? testResult.turnTest2 : initialValues;
  const currentThresholds = colorThresholds ? colorThresholds.TurnConnectivity : null;

  const {
    tcpConnectionTime,
    udpConnectionTime,
    tlsConnectionTime,
    behindProxy,
    error,
    meta,
    successfulConnections,
    totalConnections,
  } = turnTest;

  let objSimpleWidget = { text: "", error: "" };
  if (widget == "simple" && udpConnectionTime && udpConnectionTime && udpConnectionTime) {
    if (udpConnectionTime != "error") {
      objSimpleWidget.text = "UDP";
    } else {
      if (tcpConnectionTime != "error") {
        objSimpleWidget.text = "TCP";
      } else {
        if (tlsConnectionTime != "error") {
          objSimpleWidget.text = "TLS";
        } else {
          objSimpleWidget.error = "Cannot connect to TURN";
        }
      }
    }
  } else {
    widget = false;
  }
  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const { t } = useTranslation(["common", "tests"]);

  return (
    <>
      <WidgetHeader
        title={t("tests:turnConnectivity.title")}
        tooltip={t("tests:turnConnectivity.tooltip")}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <>
          {(!widget || widget === "full") && (
            <div className="widget-turn-connectivity-wrapper">
              {(!disableProtocols || !disableProtocols.includes("udp")) && (
                <div>
                  <div className="widget-turn-connectivity-result">
                    <span className="widget-turn-connectivity-title" id="TCT-udp">
                      UDP
                    </span>
                    <div className="widget-turn-connectivity-icon">
                      <ThumbUpIcon />
                    </div>
                    <div className="widget-turn-connectivity-values" id="TCT-s-udpConnectionTime">
                      <h3 id="tst-udp">{formatNumber(udpConnectionTime as number)}</h3>
                      <span>ms</span>
                    </div>
                  </div>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tcp")) && (
                <div>
                  <div className="widget-turn-connectivity-result">
                    <span className="widget-turn-connectivity-title" id="TCT-tcp">
                      TCP
                    </span>
                    <div className="widget-turn-connectivity-icon">
                      <ThumbUpIcon />
                    </div>
                    <div className="widget-turn-connectivity-values" id="TCT-s-tcpConnectionTime">
                      <h3 id="tst-tcp">{formatNumber(tcpConnectionTime as number)}</h3>
                      <span>ms</span>
                    </div>
                  </div>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tls")) && (
                <div>
                  <div className="widget-turn-connectivity-result">
                    <span className="widget-turn-connectivity-title" id="TCT-tls">
                      TLS
                    </span>
                    <div className="widget-turn-connectivity-icon">
                      <ThumbUpIcon />
                    </div>
                    <div className="widget-turn-connectivity-values" id="TCT-s-tlsConnectionTime">
                      <h3 id="tst-tls">{formatNumber(tcpConnectionTime as number)}</h3>
                      <span>ms</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {(!widget || widget === "full") && testResult?.turnTest2 && (
            <div className="widget-turn-connectivity-wrapper">
              {(!disableProtocols || !disableProtocols.includes("udp")) && (
                <div>
                  <div className="widget-turn-connectivity-result">
                    <span
                      className="widget-turn-connectivity-title"
                      id="TCT-r-udpConnectionTime-Full"
                    >
                      UDP
                    </span>
                    <div className="widget-turn-connectivity-icon">
                      <ThumbUpIcon />
                    </div>
                    <div
                      className="widget-turn-connectivity-values"
                      id="TCT-s-udpConnectionTime-Full"
                    >
                      <h3 id="tst-udp">{turnTest2.udpConnectionTime}</h3>
                      <span>ms</span>
                    </div>
                  </div>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tcp")) && (
                <div>
                  <div className="widget-turn-connectivity-result">
                    <span
                      className="widget-turn-connectivity-title"
                      id="TCT-r-tcpConnectionTime-Full"
                    >
                      TCP
                    </span>
                    <div className="widget-turn-connectivity-icon">
                      <ThumbUpIcon />
                    </div>
                    <div
                      className="widget-turn-connectivity-values"
                      id="TCT-s-tcpConnectionTime-Full"
                    >
                      <h3 id="tst-tcp">{turnTest2.tcpConnectionTime}</h3>
                      <span>ms</span>
                    </div>
                  </div>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tls")) && (
                <div>
                  <div className="widget-turn-connectivity-result">
                    <span
                      className="widget-turn-connectivity-title"
                      id="TCT-r-tlsConnectionTime-Full"
                    >
                      TLS
                    </span>
                    <div className="widget-turn-connectivity-icon">
                      <ThumbUpIcon />
                    </div>
                    <div
                      className="widget-turn-connectivity-values"
                      id="TCT-s-tlsConnectionTime-Full"
                    >
                      <h3 id="tst-tls">{turnTest2.tlsConnectionTime}</h3>
                      <span>ms</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withTheme()(NewTurnConnectivityTest);
