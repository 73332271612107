import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ExplanationMessage from "../ExplanationMessage";
import ControlBar from "../ControlBar/ControlBar";
import TestsContainer from "../TestsContainer/TestsContainer";
import AdditionalTestsContainer from "../TestsContainer/AdditionalTestsContainer";
import ProgressBar from "../ProgressBar/ProgressBar";
import logo from "../../assets/images/logo.jpg";
import PrintInfoBox from "../PrintInfoBox";
import LogsComponent from "../LogModal/logs";
import "./MainContent.scss";
import { LogModalProvider } from "../LogModal/LogModalProvider";
import PermissionModal from "../PermissionModal";
import useMediaDevices from "../../helpers/customHooks/usePermissionTracker";
import useExtensionTracker from "../../helpers/customHooks/useExtensionTracker";
import CompanionModal from "../CompanionModal/companionModal";
const styles = (theme: any) => ({
  wrapper: {},
  container: {
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
    padding: "0 15px",
  },
  powered: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 16,
  },
  powered_avatour: {
    width: 200,
    margin: "0 auto",
    paddingBottom: 0,
    background: "#fff",
  },
  poweredText: {
    fontWeight: 600,
    color: "#333",
    fontSize: 10,
  },
  poweredLogo: {
    width: 47,
    height: "auto",
    display: "block",
    marginLeft: 4,
  },
});
function mapStateToProps(state: any) {
  return {
    backgroundColor: state.document.layout.backgroundColor,
    hidePoweredByRTC: state.document.layout.hidePoweredByRTC,
    theme: state.document.theme,
    config: state.tests.config,
    testIsLoading: state.document.testIsLoading,
  };
}

interface IMainContentProps {
  classes: any;
  backgroundColor?: string;
  hidePoweredByRTC?: boolean;
  theme?: any;
  config?: any;
  testIsLoading?: boolean;
}

const wrapperStyle = (backgroundColor?: string) => {
  return {
    backgroundColor: backgroundColor || "unset",
  };
};

const MainContent = (props: IMainContentProps) => {
  const { classes, backgroundColor, hidePoweredByRTC, theme, config } = props;
  const isCompanionForced = props.config? props.config.options["companion"] && props.config.options["companion"] === 'force' ? true: false: false;
  //commenting the actuall condition and hiding the hidePermissionModal for https://redmine.testrtc.com/issues/9451
  // let hidePermissionModal = useMediaDevices(config);
  let hidePermissionModal = true;
  let hideInstallExtensionModal = !isCompanionForced;
  const checkExtension  = useExtensionTracker();
  
  if(isCompanionForced) {
    hideInstallExtensionModal = checkExtension;
  }
 
  
  return (
    <LogModalProvider>
      <div
        className={`${classes.wrapper} main-content-wrapper-${theme}`}
        style={wrapperStyle(backgroundColor)}
      >
        <div className={classes.container}>
          {config && config.options.hidewidgets !== "true" && (
            <React.Fragment>
              <ExplanationMessage />
              <PrintInfoBox />
            </React.Fragment>
          )}
          <ControlBar hideInstallExtensionModal={hideInstallExtensionModal}/>
          {config && config.options.hidewidgets !== "true" && (
            <React.Fragment>
              <TestsContainer />
              <AdditionalTestsContainer />
            </React.Fragment>
          )}
          {props.config &&
            props.config.options &&
            props.config.options["report"] === "pdf" &&
            props.config.includeLogs && (
              <React.Fragment>
                <div className="html2pdf__page-break"></div>
                <LogsComponent scrollable={false} />
              </React.Fragment>
            )}
           {props.testIsLoading &&  <CompanionModal open={!hideInstallExtensionModal} />}
          {props.testIsLoading && hideInstallExtensionModal && (
            <PermissionModal open={!hidePermissionModal} />
          )}
          {hidePermissionModal && hideInstallExtensionModal && <ProgressBar />}

          {!hidePoweredByRTC && (
            <a
              className={`poweredBy ${classes.powered} ${classes[`powered_${theme}`]}`}
              href="https://testrtc.com"
              target="_blank"
            >
              <span className={classes.poweredText}>Powered by</span>
              <img className={classes.poweredLogo} src={logo} alt="logo" />
            </a>
          )}
        </div>
      </div>
    </LogModalProvider>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(MainContent));
