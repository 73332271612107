import React from "react";
import DonutChart from "../UI/DonutChart";
import { RowItem } from "../UI/RowItem";
import "../UI/Widget.css";
import WidgetHeader from "../UI/WidgetHeader";
import { useTranslation } from "react-i18next";

import withTheme from "@material-ui/core/styles/withTheme";
import { TwoColumnLayout } from "../UI/Layouts";
import { ICommonCallQulaityTestProps } from "../../CallQualityTest";
import { formatNumber, getColorValue } from "../../../../../helpers/utils";
import { ITestsResult } from "twillio-tests/core/TestResults";
import ErrorState from "../UI/ErrorState";

interface IDetailedProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
  testResult?: ITestsResult;
}

const Detailed = (props: IDetailedProps) => {
  const { theme, testCall, mainColor, currentThresholds, estimateTime, knowledgeUrl, testResult } =
    props;

  const { avgJit, avgJitOut, avgPl, avgOutPl, avgRtt, mark, error, mosInbound, transportProtocol } =
    testCall;

  const { t } = useTranslation(["common", "tests"]);

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
          warningValueMax: threshold.warningMax,
          warningValueMin: threshold.warningMin,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const mosStatus = (mark: any) => {
    if (mark && mark !== "N/A") {
      mark = parseFloat(mark);
      let overall =
        mark > 4 ? "Excellent" : mark > 3 ? "Good" : mark > 2 ? "Fair" : mark > 1 ? "Poor" : "Bad";
      return overall;
    } else {
      return "";
    }
  };

  const mosValue = (value: any) => {
    return value && value !== "N/A" ? value.toFixed(1) : "N/A";
  };

  return (
    <>
      <WidgetHeader
        title={t("tests:call-quality.title")}
        tooltip={t("tests:call-quality.tooltip")}
        estimateTime={estimateTime}
        knowledgeUrl={knowledgeUrl}
        error={error}
        badge={transportProtocol}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <TwoColumnLayout>
          <TwoColumnLayout.Left>
            <div className="twoColumn-container">
              <span className="twoColumn-chart-title">in</span>
              <DonutChart
                value={mosValue(mark)}
                small
                parentId="CQC-r-MOSScore"
                childId="CQC-c-mosValue2"
              />
              <span style={{ opacity: 0 }}>in</span>
            </div>
            <span className="widget-status-text">{mosStatus(mark)}</span>
            <div className="twoColumn-row-container">
              <RowItem title="Round trip" unit="ms" parentId="CQC-r-roundTrip" />
              <RowItem
                title="Packet loss"
                value={avgPl}
                unit="%"
                color={getColor(avgPl, currentThresholds?.avgPl)}
                parentId="CQC-r-packetLoss"
                childId="CQC-c-avgPl"
              />
              <RowItem
                title="Jitter"
                value={avgJit}
                unit="ms"
                color={getColor(avgJit, currentThresholds?.avgJit)}
                parentId="CQC-r-jitter"
                childId="CQC-c-avgJit"
              />
            </div>
          </TwoColumnLayout.Left>
          <TwoColumnLayout.Right>
            <div className="twoColumn-container">
              <span className="twoColumn-chart-title">out</span>
              <DonutChart
                value={mosValue(mosInbound)}
                small
                parentId="CQC-r-MOSScore"
                childId="CQC-c-mosValue"
              />
              <span style={{ opacity: 0 }}>out</span>
            </div>
            <span className="widget-status-text">{mosStatus(mosInbound)}</span>
            <div className="twoColumn-row-container">
              <RowItem
                title="Round trip"
                value={avgRtt}
                unit="ms"
                color={getColor(avgRtt, currentThresholds?.avgRtt)}
                parentId="CQC-r-roundTrip"
                childId="CQC-c-avgRtt"
              />
              <RowItem
                title="Packet loss"
                value={avgOutPl}
                unit="%"
                color={getColor(avgOutPl, currentThresholds?.avgPl)}
                parentId="CQC-r-packetLoss"
                childId="CQC-c-avgOutPl"
              />
              <RowItem
                title="Jitter"
                value={avgJitOut}
                unit="ms"
                color={getColor(avgJitOut, currentThresholds?.avgJit)}
                parentId="CQC-r-jitter"
                childId="CQC-c-avgJitOut"
              />
            </div>
          </TwoColumnLayout.Right>
        </TwoColumnLayout>
      )}
    </>
  );
};

export default withTheme()(Detailed);
