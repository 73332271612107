import React from "react";

interface Props {
  label: string;
  value: number;
  sessionValue: number;
  color?: string;
  sessionColor?: string;
  parentId?: string;
  valueId?: string;
  sessionId?: string;
}

const ThroughputRow = ({
  label,
  value,
  sessionValue,
  color,
  sessionColor,
  parentId,
  valueId,
  sessionId,
}: Props) => {
  return (
    <div className="throughput-row-container" id={parentId}>
      <p className="throughput-row-label">{label}</p>
      <div className="throughput-row-values">
        <h4 style={{ color: color }} id={valueId}>
          {value} <span>kbps</span>
        </h4>
        <h4 style={{ color: sessionColor }} id={sessionId}>
          {sessionValue} <span>sessions</span>
        </h4>
      </div>
    </div>
  );
};

export default ThroughputRow;
