import React from "react";
import WidgetHeader from "./WidgetHeader";
import "./Widget.css";

interface CardContainer {
  children: React.ReactNode;
  id: string;
}

const CardContainer = ({ children, id }: CardContainer) => {
  return (
    <div className="container" id={id}>
      {children}
    </div>
  );
};

export default CardContainer;
