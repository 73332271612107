import { withTheme } from "@material-ui/core";
import React from "react";
import { ITestsResult, IVideoBandwidth } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { useTranslation } from "react-i18next";
import WidgetHeader from "./UI/WidgetHeader";
import { RowItem } from "./UI/RowItem";
import "./UI/Widget.css";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import ErrorState from "./UI/ErrorState";

interface IVideoBandwidthTestProps {
  videoQualityMode?: boolean;
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  testTitle?: string;
}

const initialValues: IVideoBandwidth = {
  bandwidthEstimate: undefined,
  bitrate: undefined,
  jitter: undefined,
  roundTripTime: undefined,
  fractionLoss: undefined,
};

const NewVideoBandwidthTest = (props: IVideoBandwidthTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    knowledgeUrl,
    estimateTime,
    colorThresholds,
    videoQualityMode,
    testName,
    testTitle,
  } = props;
  const getTestName = (): string => {
    if (videoQualityMode) {
      return "videoQualityTest";
    } else if (testName && testName === "VideoBandwidth2") {
      return "videoBandwidthTest2";
    } else {
      return "videoBandwidthTest";
    }
  };

  const videoBandwidthTest = (testResult && testResult[getTestName()]) || initialValues;
  const currentThresholds: any = videoQualityMode
    ? colorThresholds?.VideoQuality
    : colorThresholds?.VideoBandwidth;

  const { bandwidthEstimate, jitter, roundTripTime, fractionLoss, error } = videoBandwidthTest;

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const bitrate = isNaN(Number(videoBandwidthTest.bitrate))
    ? undefined
    : videoBandwidthTest.bitrate;

  const getTestTitle = (): string => {
    if (videoQualityMode) {
      return t("tests:video.quality");
    } else if (testTitle) {
      return t(testTitle);
    } else {
      return t("tests:video:bandwidth");
    }
  };

  // NEED MORE INFO
  const formatBandwidthEstimate = (value: number | string) => {
    if (!bitrate) {
      return formatNumber(Number(value));
    } else if (!value) {
      return formatNumber(Number(value));
    } else return "-";
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = getTestTitle();
  return (
    <>
      <WidgetHeader
        title={title}
        tooltip={title}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <div className="widget-callquality-onecolumn">
          <div className="widget-callquality-chart">
            <div className="widget-bandwidth-estimatedtime">
              <h1>{t("estimated")}</h1>
              <p>
                <span>{formatBandwidthEstimate(bandwidthEstimate)}</span>
                <span>ms</span>
              </p>
            </div>
          </div>
          <div className="widget-callquality-rows">
            <RowItem
              title={t("jitter")}
              unit="ms"
              value={jitter}
              color={defaultProps.color}
              formatting={(value: string | number) => Number(value).toFixed(0)}
            />
            <RowItem
              title={t("Round Trip")}
              value={roundTripTime}
              unit="ms"
              color={getColor(roundTripTime, currentThresholds?.roundTripTime)}
              formatting={(value: string | number) => formatNumber(Number(value))}
            />
            <RowItem
              title="Packet loss"
              value={fractionLoss}
              unit="%"
              color={getColor(fractionLoss, currentThresholds?.fractionLoss)}
              formatting={(value: string | number) => formatNumber(Number(value))}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withTheme()(NewVideoBandwidthTest);
