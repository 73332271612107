import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import { IDeviceState, ITestsResult } from "twillio-tests/core/TestResults";
import WidgetHeader from "./UI/WidgetHeader";
import { OneColumnLayout } from "./UI/Layouts";
import { AudioOff, AudioOn, VideoOff, VideoOn } from "./UI/Icons";
import OneColumnRow from "./UI/OneColumnRow";

interface ILocationTestProps {
  mainColor: string;
  testResult?: ITestsResult;
  theme: any;
  currentTheme?: string;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  showInMap?: boolean;
}

const initialValues = {
  audio: "-",
  device: "-",
  network: "-",
};
let mediaPathFlag: boolean = true;
const DeviceStateTest = (props: ILocationTestProps) => {
  const { testResult, knowledgeUrl, estimateTime, currentTheme } = props;
  currentTheme === "livevox" ? (mediaPathFlag = false) : (mediaPathFlag = true);
  const deviceStateTest: IDeviceState =
    testResult && testResult.deviceState ? testResult.deviceState : initialValues;

  const region =
    testResult && testResult.testCall && testResult.testCall.region
      ? testResult.testCall.region
      : "-";

  const { t } = useTranslation(["common", "tests"]);

  const deviceName = deviceStateTest.device;
  const authorization =
    deviceStateTest.audio !== "-" ? t(`tests:devicestate.${deviceStateTest.audio}`) : "-";
  const network = deviceStateTest.network;

  let video = false;

  return (
    <>
      <WidgetHeader
        title={t("tests:devicestate.title")}
        tooltip={t("tests:devicestate.tooltip")}
        error={""}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      <OneColumnLayout>
        <OneColumnLayout.Top>
          <div
            className="device-state-wrapper"
            style={{ justifyContent: video ? "space-between" : "center" }}
          >
            <div className="device-state-container" id="DST-r-authorization">
              <p>Audio</p>
              {authorization === "Allowed" ? <AudioOn /> : <AudioOff />}
              <span id="DST-c-authorization">
                {authorization === "Allowed" ? "Allowed" : "Not Allowed"}
              </span>
            </div>
            {video && (
              <div className="device-state-container" id="DST-r-authorization">
                <p>Video</p>
                {authorization === "Allowed" ? <VideoOn /> : <VideoOff />}
                <span id="DST-c-authorization">
                  {authorization === "Allowed" ? "Allowed" : "Not Allowed"}
                </span>
              </div>
            )}
          </div>
        </OneColumnLayout.Top>
        <OneColumnLayout.Bottom>
          <div className="oneColumn-result-row-container">
            <OneColumnRow
              title={t("tests:devicestate.network")}
              value={network}
              parentId="DST-r-network"
              childId="DST-c-network"
            />
            {mediaPathFlag && region !== "-" && region !== "roaming" && (
              <OneColumnRow
                title={t("tests:devicestate.mediapath")}
                value={region}
                parentId="DST-r-region"
                childId="DST-c-region"
              />
            )}
            <OneColumnRow
              title={t("tests:devicestate.device")}
              value={deviceName}
              parentId="DST-r-deviceName"
              childId="DST-c-deviceName"
            />
          </div>
        </OneColumnLayout.Bottom>
      </OneColumnLayout>
    </>
  );
};

export default withTheme()(DeviceStateTest);
