import React, { ReactNode } from "react";
import "./Widget.css";

export const OneColumnLayout = ({ children }: { children: ReactNode }) => {
  return <div className="oneColumn">{children}</div>;
};

OneColumnLayout.Top = ({ children }: { children: ReactNode }) => (
  <div className="oneColumn-top">{children}</div>
);
OneColumnLayout.Bottom = ({ children }: { children: ReactNode }) => (
  <div className="oneColumn-bottom">{children}</div>
);

OneColumnLayout.TopLeft = ({ children }: { children: ReactNode }) => (
  <div className="oneColumn-topLeft">{children}</div>
);

OneColumnLayout.TopRight = ({ children }: { children: ReactNode }) => (
  <div className="oneColumn-topRight">{children}</div>
);

export const TwoColumnLayout = ({ children }: { children: ReactNode }) => {
  return <div className="twoColumn">{children}</div>;
};

TwoColumnLayout.Left = ({ children }: { children: ReactNode }) => (
  <div className="twoColumn-left">{children}</div>
);
TwoColumnLayout.Right = ({ children }: { children: ReactNode }) => (
  <div className="twoColumn-right">{children}</div>
);
