import React from "react";

import { useTranslation } from "react-i18next";
import WidgetHeader from "../UI/WidgetHeader";
import { withTheme } from "@material-ui/core";
import DonutChart from "../UI/DonutChart";
import { RowItem } from "../UI/RowItem";
import { ICommonCallQulaityTestProps } from "../../CallQualityTest";
import { formatNumber, getColorValue } from "../../../../../helpers/utils";
import ErrorState from "../UI/ErrorState";
import { ITestsResult } from "twillio-tests/core/TestResults";

interface IDefaultProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
  testResult?: ITestsResult;
}

const Default = (props: IDefaultProps) => {
  const { theme, testCall, config, mainColor, currentThresholds, testResult } = props;
  const { avgJit, avgPl, avgRtt, mark, error, mosInbound, transportProtocol } = testCall;
  const codec = testCall.codec || (config as any).CallQuality?.codec;

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const titleCodec = codec === "G711" ? "G.711" : "";

  const region = testCall.region || "-";

  const { t } = useTranslation(["common", "tests"]);

  const mosValue = (config: any, mosInbound: any, mark: any) => {
    let score;
    let isScoreAvailable;

    if (config.CallQuality?.mosCalculation !== "twilio") {
      isScoreAvailable = mosInbound && mosInbound !== "N/A" && mosInbound >= 0;
      score = mosInbound;
    } else {
      isScoreAvailable = !!mark;
      score = mark;
    }

    if (isScoreAvailable) {
      return score.toFixed(1);
    } else {
      return "No Data";
    }
  };

  const mosStatus = (config: any, mosInbound: any, mark: any) => {
    let score;
    if (config.CallQuality?.mosCalculation !== "twilio") {
      score = mosInbound;
    } else {
      score = mark;
    }

    if (score > 4) {
      return "Excellent";
    } else if (score > 3) {
      return "Good";
    } else if (score > 2) {
      return "Fair";
    } else if (score > 1) {
      return "Poor";
    } else {
      return "Bad";
    }
  };

  return (
    <>
      <WidgetHeader
        title={t("tests:call-quality.title", { codec: titleCodec ? " - " + titleCodec : "" })}
        tooltip={t("tests:call-quality.tooltip")}
        error={error}
        knowledgeUrl={props.knowledgeUrl}
        estimateTime={props.estimateTime}
        badge={transportProtocol}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <div>
          <div className="widget-callquality-onecolumn">
            <div className="widget-callquality-chart">
              <DonutChart
                value={Number(mosValue(config, mosInbound, mark))}
                status={mosStatus(config, mosInbound, mark)}
                parentId="CQC-r-MOSScore"
                childId="CQC-c-mosValue"
              />
            </div>
            <div className="widget-callquality-rows">
              <RowItem
                title="Round trip"
                unit="ms"
                value={avgRtt}
                color={getColor(avgRtt, currentThresholds?.avgRtt)}
                parentId="CQC-r-roundTrip"
                childId="CQC-c-avgRtt"
              />
              <RowItem
                title="Packet loss"
                value={avgPl}
                unit="%"
                color={getColor(avgPl, currentThresholds?.avgRtt)}
                parentId="CQC-r-packetLoss"
                childId="CQC-c-avgPl"
              />
              <RowItem
                title="Jitter"
                value={avgJit}
                unit="ms"
                color={getColor(avgJit, currentThresholds?.avgJit)}
                parentId="CQC-r-jitter"
                childId="CQC-c-avgJit"
              />
            </div>
          </div>
          {config?.showRegion && (
            <div className="widget-callquality-region">
              <RowItem
                title="Region"
                value={region}
                parentId="CQC-r-Region"
                childId="CQC-c-Region"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withTheme()(Default);
