import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { ISpeed, ITestsResult } from "twillio-tests/core/TestResults";
import WidgetHeader from "./UI/WidgetHeader";
import { OneColumnLayout } from "./UI/Layouts";
import { ArrowDownIcon, ArrowUpIcon } from "./UI/Icons";
import OneColumnRow from "./UI/OneColumnRow";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import ErrorState from "./UI/ErrorState";

interface IBandwidthTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues: ISpeed = {
  download: undefined,
  jitter: undefined,
  upload: undefined,
  region: undefined,
};

const NewBandwidthBestTest = (props: IBandwidthTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName } =
    props;
  const speedTest = testResult && testResult.speedTest ? testResult.speedTest.best : initialValues;
  const currentThresholds = colorThresholds?.BandwidthSpeed || null;

  const { download, jitter, upload, region, error } = speedTest;

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const getColor = (value: any, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const { t } = useTranslation(["common", "tests"]);
  return (
    <>
      <WidgetHeader
        title={t("tests:bandwidth.title")}
        tooltip={t("tests:bandwidth.tooltip")}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <OneColumnLayout>
          <OneColumnLayout.Top>
            <div className="oneColumn-top-elements">
              <div className="oneColumn-top-iconwithtext">
                <ArrowDownIcon />
                <span>{t("downlink")}</span>
              </div>
              <div className="oneColumn-top-results" id="BWTA-r-downlink">
                <span
                  className="oneColumn-top-results-value"
                  style={{
                    color: getColor(download, currentThresholds?.download),
                  }}
                  id="BWTA-c-downlink"
                >
                  {download}
                </span>
                <span className="oneColumn-top-results-unit">Mbps</span>
              </div>
            </div>
            <div className="oneColumn-top-elements">
              <div className="oneColumn-top-iconwithtext">
                <ArrowUpIcon />
                <span>{t("uplink")}</span>
              </div>
              <div className="oneColumn-top-results" id="BWTA-r-uplink">
                <span
                  className="oneColumn-top-results-value"
                  style={{
                    color: getColor(upload, currentThresholds?.upload),
                  }}
                  id="BWTA-c-upload"
                >
                  {formatNumber(Number(upload))}
                </span>
                <span className="oneColumn-top-results-unit">Mbps</span>
              </div>
            </div>
          </OneColumnLayout.Top>
          <OneColumnLayout.Bottom>
            <div style={{ marginTop: "8px" }}>
              <div className="oneColumn-result-row-container">
                <OneColumnRow
                  title={t("jitter")}
                  value={jitter}
                  color={getColor(jitter, currentThresholds?.jitter)}
                  parentId="BWTA-r-jitter"
                  childId="BWTA-c-jitter"
                />
                <OneColumnRow
                  title={t("tests:bandwidthspeed:best-region")}
                  value={region}
                  parentId="BWTA-r-region"
                  childId="BWTA-c-region"
                />
              </div>
            </div>
          </OneColumnLayout.Bottom>
        </OneColumnLayout>
      )}
    </>
  );
};

export default withTheme()(NewBandwidthBestTest);
