/*global chrome*/
import React, { useEffect, useState } from "react";
const noop = () => {};
const useExtensionTracker = () => {
  const userAgent = navigator.userAgent.includes("Chrome");

  const [state, setState] = useState(true);
  const [isInstalled, setIsInstalled] = useState(false);
  useEffect(() => {
    setIsInstalled(checkStatus());
  }, []);
  useEffect(() => {
    if (isInstalled) {
      setState(true);
    } else {
      setState(false);
    }
  }, [isInstalled]);
  useEffect(() => {
    if(userAgent && chrome && chrome.runtime) {
      setIsInstalled(checkStatus());
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAgent]);
  const checkStatus = () => {
    if (userAgent && chrome && chrome.runtime) {
      const connection = chrome.runtime.connect("ninnkfgcogbifinffmalccnoijlhhnok");
      if (connection) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return state;
};
export default useExtensionTracker;