import React from "react";
import WidgetHeader from "./UI/WidgetHeader";
import { useTranslation } from "react-i18next";
import { ITestsResult, IVideoP2P } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";

import { RowItem } from "./UI/RowItem";
import { withTheme } from "@material-ui/core";
import { TwoColumnLayout } from "./UI/Layouts";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import ErrorState from "./UI/ErrorState";

interface IVideoP2PTestTest {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  videoQualityMode?: boolean;
  testName?: string;
}

const initialValues: IVideoP2P = {
  bandwidthEstimateIn: undefined,
  bandwidthEstimateOut: undefined,

  bitrate: undefined,
  bitrateOut: undefined,

  roundTripTime: undefined,

  fractionLoss: undefined,
  fractionLossOut: undefined,
};

const VideoP2P = (props: IVideoP2PTestTest) => {
  const {
    mainColor,
    testResult,
    theme,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    videoQualityMode,
    testName,
  } = props;

  const results =
    (testResult && testResult[videoQualityMode ? "videoQualityTest" : "videoP2P"]) || initialValues;

  const currentThresholds = videoQualityMode
    ? colorThresholds?.VideoQuality
    : colorThresholds?.VideoP2P;

  const {
    error,
    bandwidthEstimateOut,
    bitrate,
    bitrateOut,
    roundTripTime,
    fractionLoss,
    fractionLossOut,
  } = results;

  const { t } = useTranslation(["common", "tests"]);
  const title = videoQualityMode ? t("tests:video.quality") : t("tests:video.p2p");

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  return (
    <>
      <WidgetHeader
        title={title}
        tooltip={title}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <TwoColumnLayout>
          <TwoColumnLayout.Left>
            <div className="twoColumn-container" style={{ marginBottom: "6px" }}>
              <span className="twoColumn-chart-title">in</span>
            </div>
            <div className="twoColumn-row-container">
              <RowItem title={t("estimated")} />
              <RowItem
                title={t("bitrate")}
                value={bitrate}
                unit="kbps"
                formatting={(value: string | number) => formatNumber(Number(value))}
                color={getColor(bitrate, currentThresholds?.bitrate)}
                parentId={`${testName}-r-bitrate`}
                childId={`${testName}-c-bitrate`}
              />
              <RowItem
                title={t("Round Trip")}
                formatting={(value: string | number) => formatNumber(Number(value))}
                color={getColor(roundTripTime, currentThresholds?.roundTripTime)}
              />
              <RowItem
                title={t("Packet Loss")}
                value={fractionLoss}
                unit="%"
                formatting={(value: string | number) => formatNumber(Number(value))}
                color={getColor(fractionLoss, currentThresholds?.fractionLoss)}
                parentId={`${testName}-r-packetLoss`}
                childId={`${testName}-c-fractionLoss`}
              />
            </div>
          </TwoColumnLayout.Left>
          <TwoColumnLayout.Right>
            <div className="twoColumn-container" style={{ marginBottom: "6px" }}>
              <span className="twoColumn-chart-title">out</span>
            </div>
            <div className="twoColumn-row-container">
              <RowItem
                title={t("estimated")}
                unit="kbps"
                value={bandwidthEstimateOut}
                color={getColor(bandwidthEstimateOut, currentThresholds?.bandwidthEstimateOut)}
                parentId={`${testName}-r-estimated`}
                childId={`${testName}-c-bandwidthEstimateOut`}
              />
              <RowItem
                title={t("bitrate")}
                value={bitrateOut}
                unit="kbps"
                formatting={(value: string | number) => formatNumber(Number(value))}
                color={getColor(bitrateOut, currentThresholds?.bitrateOut)}
                parentId={`${testName}-r-bitrate`}
                childId={`${testName}-c-bitrateOut`}
              />
              <RowItem
                title={t("Round Trip")}
                value={roundTripTime}
                unit="ms"
                formatting={(value: string | number) => formatNumber(Number(value))}
                color={getColor(roundTripTime, currentThresholds?.roundTripTime)}
                parentId={`${testName}-r-roundTrip`}
                childId={`${testName}-c-roundTripTime`}
              />
              <RowItem
                title={t("Packet Loss")}
                value={fractionLoss}
                unit="%"
                formatting={(value: string | number) => formatNumber(Number(value))}
                color={getColor(fractionLossOut, currentThresholds?.fractionLoss)}
                parentId={`${testName}-r-packetLoss`}
                childId={`${testName}-c-fractionLossOut`}
              />
            </div>
          </TwoColumnLayout.Right>
        </TwoColumnLayout>
      )}
    </>
  );
};

export default withTheme()(VideoP2P);
