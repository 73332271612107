import React from "react";

const Bar = ({ value, max, color }: any) => {
  const height = `${(value / max) * 100}%`;

  return (
    <div
      style={{
        height: height,
        backgroundColor: color,
      }}
      className="throughput-bar"
    />
  );
};

export default Bar;
