import React from "react";
import SlicedDonutChart from "./UI/SlicedDonutChart";
import WidgetHeader from "./UI/WidgetHeader";
import { TwoColumnLayout } from "./UI/Layouts";
import { IDNSLookup, ITestsResult } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core";
import { RowItem } from "./UI/RowItem";
import ErrorState from "./UI/ErrorState";

interface IDNSLookupTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  currentTheme?: string;
}

const initialValues = {
  error: undefined,
  totalConnections: undefined,
  successfulConnections: undefined,
  averageConnectionTime: undefined,
  highestConnectionTime: undefined,
  shortestConnectionTime: undefined,
};

const NewDNSLookUpTest = (props: IDNSLookupTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    knowledgeUrl,
    estimateTime,
    colorThresholds,
    testName,
    currentTheme,
  } = props;
  const dnsLookup: IDNSLookup =
    testResult && testResult.dnsLookup ? testResult.dnsLookup : initialValues;
  const currentThresholds = colorThresholds?.DNSLookup || null;
  const {
    error,
    meta,
    totalConnections,
    successfulConnections,
    averageConnectionTime,
    highestConnectionTime,
    shortestConnectionTime,
  } = dnsLookup;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: (value: number) => `${formatNumber(value)} <span>ms</span>`,
  };

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const { t } = useTranslation(["common", "tests"]);

  return (
    <>
      <WidgetHeader
        title={t("tests:dns.title")}
        tooltip={t("tests:dns.title")}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <div className="widget-dns-lookup-wrapper">
          <div className="widget-dns-lookup">
            {totalConnections !== undefined && successfulConnections !== undefined && (
              <SlicedDonutChart
                x={totalConnections}
                y={totalConnections - successfulConnections}
                parentId="DNSL-r-connected"
                childId="DNSL-c-connected"
              />
            )}
            <div className="widget-dns-lookup-row-container">
              <span>Connection time</span>
              <div className="widget-dns-lookup-rows">
                <RowItem
                  title="Average"
                  unit="ms"
                  value={averageConnectionTime}
                  parentId="DNSL-r-average"
                  childId="DNSL-c-average"
                />
                <RowItem
                  title="Longest"
                  unit="ms"
                  value={highestConnectionTime}
                  parentId="DNSL-r-highest"
                  childId="DNSL-c-highest"
                />
                <RowItem
                  title="Shortest"
                  unit="ms"
                  value={shortestConnectionTime}
                  parentId="DNSL-r-shortest"
                  childId="DNSL-c-shortest"
                />
              </div>
            </div>
          </div>
          {error && (
            <div className="widget-dns-lookup-failed-domain">
              <span>Failed domain:</span>
              <p>https://amaacwrtc01.companyname.com</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withTheme()(NewDNSLookUpTest);
