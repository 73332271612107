import axios from "axios";
import axiosRetry from "axios-retry";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { IReportOptions } from "../interfaces";
import { getParameters, IsLocalhost, sanitizeData } from "../helpers/utils";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { ITestsResultToServer } from "twillio-tests/core/TestResults";
import { sha256 } from "crypto-hash";
import html2pdf from "html2pdf.js";
import { delay } from "lodash";
// axiosRetry(axios, { retries: 3 });
const dev = false;

export default class DocumentService {
  static async getLayout() {
    const uuid: any = window.location.pathname.replace("/", "");
    let options = getParameters();
    const report = options.report;

    let currentUuid = uuid;
    const viewMode = !!uuid;

    try {
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      const answer = await axios.get(
        `${netlifyBaseURL}/.netlify/functions/get-config?config=${options.config || ""}&region=${
          options.region || null
        }&host=${options.host || null}`
      );
      let data = answer.data.config;
      if (typeof answer.data === "string") {
        // probebly invalid config JSON file so we recived as string and not as json, this will fail
        data = JSON.parse(answer.data);
      }
      let config: TestConfiguration = data as TestConfiguration;
      config.debug = false;
      config.location = answer.data.location;
      if (options.run && options.run?.split(",").length > 0) {
        config.testsList = options.run.split(",");
      }

      if (
        config.inviteAccessOnly &&
        (!options.invite || options.invite === "") &&
        !uuid &&
        !IsLocalhost()
      ) {
        throw new Error("There is no invite token provided, please provide invite token in url.");
      }

      let serverResults = null;
      try {
        if (uuid) {
          // const headerKey = dev ? "x-consumer-username" : "apikey";
          // const result = await axios.get(`${PATH}/${uuid}`, {
          //   headers: { [`${headerKey}`]: API_KEY },
          // });
          const result = await axios.get(
            `${netlifyBaseURL}/.netlify/functions/get-result?customer=${
              config.customerName || ""
            }&uuid=${uuid || null}`
          );
          serverResults = {
            ...sanitizeData(result.data.data),
            createDate: result.data.createDate,
          };
          if (options.access) {
            const sha256UUID = await sha256(uuid);
            const sha256access = options.access;
            if (sha256access === sha256UUID) {
              config.logRestrictAccess = false;
              config.isClientPortal = false;
              config.silent = false;
            }
          }
        }
      } catch (err) {
        console.error(err.stack);
        currentUuid = null;
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      try {
        if (options.invite) {
          const result = await axios.get(
            `${netlifyBaseURL}/.netlify/functions/networktest-invite?customer=${
              config.customerName || ""
            }&invite=${options.invite || null}`
          );

          const objInviteData = result.data;
          if (objInviteData.options && objInviteData.options.length > 0) {
            const objOptions = JSON.parse(objInviteData.options);
            config = {
              ...config,
              fields: (config.fields || []).concat(objOptions.fields || []),
            };
            options = { ...options, ...objOptions.queryParams };
          }
          if (objInviteData.fields) {
            config.inviteFields = { ...objInviteData.fields, title: objInviteData?.fields?.reason };
          }
        }
      } catch (err) {
        console.error(err);
        config.invalidInvite = true;
        config.invalidInviteMessage = "This link is no longer valid. It has expired.";
        // throw new Error("Invalid invite token provided, please check invite token.");
      }

      if (!config.header_url || !config.footer_url) {
        console.error("Invalid config data, missing, header_url or footer_url", {
          data: config,
        });
        throw new Error("Invalid config data, missing, header_url or footer_url");
      }

      if (options.debug && options.debug === "true") {
        console.log("===== Debug Mode =====");
        config.debug = true;
        config.disableAutoSave = true;
        config.fields = [];
        config.emailRequiredForStart = false;
        config.userRequirePassword = false;
        delete config.userAuthKey;
      }
      if (options.debug && options.debug === "test") {
        console.log("===== Test Mode =====");
        config.disableAutoSave = true;
        config.emailRequiredForStart = false;
        config.userRequirePassword = false;

        delete config.userAuthKey;
      }
      if (options.region) {
        console.log("===== Region: " + options.region + " =====");
        config.region = options.region;
      }
      if (options.save) {
        console.log("===== Save to new API key: " + options.save + " =====");
        config.API_KEY = options.save;
        config.API_URL = "https://api.testrtc.com/v1st/networktest"; // force staging
        config.disableAutoSave = false;
      }

      if (options.verbose) {
        config.verbose = options.verbose || "all";
      }
      const [headerData, footerData] = await Promise.all([
        axios.get(config.header_url),
        axios.get(config.footer_url),
      ]);
      const backgroundColor = config.backgroundColor;
      const explanationMessageColor = config.explanationMessageColor;
      const hidePoweredByRTC = config.hidePoweredByRTC;
      const emailRequiredForStart = config.emailRequiredForStart;
      const reasonFieldValidation = config.reasonFieldValidation;
      const useTextAsEmail = config.useTextAsEmail;
      const fields = config.fields;
      if (serverResults?.testResult?.mediaIp?.ip) {
        if (serverResults?.testResult?.location?.ip !== serverResults?.testResult?.mediaIp?.ip) {
          if (config && config.location) {
            config.location.widgetType = "detailed";
          }
        }
      }

      if(options.embedded === "true") {
        // tslint:disable-next-line:only-arrow-functions
        window.onkeydown = function(event: KeyboardEvent) {
          if(event.code === "KeyU" && event.ctrlKey){
            event.preventDefault();
          }
        }
      }

      const res = {
        serverResults,
        header: options.embedded !== "true" ? headerData.data : "",
        footer: options.embedded !== "true" ? footerData.data : "",
        config: { ...config, options },
        fields,
        uuid: currentUuid,
        viewMode,
        report,
        backgroundColor,
        explanationMessageColor,
        hidePoweredByRTC,
        emailRequiredForStart,
        useTextAsEmail,
        reasonFieldValidation,
      };
      console.log("Test Configuration", { res });
      return res;
    } catch (err) {
      console.error(err.stack);
      throw new Error(err);
    }
  }

  static sendReport(fieldOption: IReportOptions, uuid: any, config: TestConfiguration) {
    console.log("sendReport", { fieldOption });
    const PATH = config.API_URL;
    const API_KEY = config.API_KEY;
    const headerKey = dev ? "x-consumer-username" : "apikey";
    return axios.put(`${PATH}/${uuid}`, fieldOption, {
      headers: { [`${headerKey}`]: API_KEY },
    });
  }

  static async getPdf(fileName: string) {
    return new Promise((resolve) => {
      delay(async () => {
        const element = document.getElementsByClassName("pdfReport")[0];
        const opt = {
          pagebreak: { avoid: "img" },
          margin: [14, 0, 20, 0],
          filename: fileName,
          html2canvas: { windowWidth: 1000 },
          jsPDF: { orientation: "p", unit: "pt", format: "letter" },
        };
        // New Promise-based usage:
        const file = await html2pdf()
          .from(element)
          .set(opt)
          .outputPdf()
          .then((pdfFile: string) => pdfFile);
        resolve(btoa(file));
      }, 2000);
    });
  }

  static async getFileName(fileNameParams: any) {
    let nameFormat = "ID-YYYYMMDD-EMAIL-REASON"
      .replace("ID", fileNameParams.testRunId)
      .replace("YYYYMMDD", format(parseISO(new Date().toISOString()), "yyyyMMdd"))
      .replace("EMAIL", fileNameParams.email)
      .replace("REASON", fileNameParams.title)
      .replace(/\s/g, "-");
    if (nameFormat.length <= 250) {
      return `${nameFormat}.pdf`;
    } else {
      nameFormat = nameFormat.substring(0, 250);
      return `${nameFormat}.pdf`;
    }
  }

  static async sendResult(
    testRunId: string,
    data: ITestsResultToServer,
    config: TestConfiguration
  ) {
    const PATH = config.API_URL;
    const API_KEY = config.API_KEY;
    // if (!PATH) {
    //   throw new Error("Missing config API_URL");
    // }
    // if (!API_KEY) {
    //   throw new Error("Missing config API_KEY");
    // }

    try {
      console.log("sendResult", { data });
      data.testResult["configName"] = config.configName;
      if (data.testResult?.testCall) {
        const widgetVariant = config?.CallQuality?.widgetVariant;
        const mosCalculation = config?.CallQuality?.mosCalculation;
        const testCall = data.testResult.testCall;

        if (widgetVariant === "detailed" || widgetVariant === "combined") {
          if (testCall.mark && testCall.mosInbound) {
            testCall["mosByWidgetType"] = ((testCall.mark + testCall.mosInbound) / 2).toFixed(1);
          } else {
            testCall["mosByWidgetType"] = testCall.mark || testCall.mosInbound;
          }
        } else if (mosCalculation !== "twilio") {
          if (testCall.mosInbound && testCall.mosInbound >= 0) {
            testCall["mosByWidgetType"] = testCall.mosInbound.toFixed(1);
          } else {
            testCall["mosByWidgetType"] = "N/A";
          }
        } else {
          testCall["mosByWidgetType"] = testCall.mark ? testCall.mark.toFixed(1) : "N/A";
        }

        if (isNaN(testCall["mosByWidgetType"])) {
          testCall["mosByWidgetType"] = "N/A";
        }
      }
      const headerKey = dev ? "x-consumer-username" : "apikey";
      let fileName = "network-test-highlights.pdf";
      if (config.theme === "livevox") {
        fileName = await this.getFileName({
          testRunId,
          email: data["email"],
          title: data["title"],
        });
      }
      const file = config.emailAlertPdf ? await this.getPdf(fileName) : null;
      const apiData = {
        test: data,
        testId: testRunId,
      };
      if (config.options.pii) {
        apiData["pii"] = config.options.pii;
      }
      if (file) {
        apiData["file"] = file;
        apiData["fileName"] = fileName;
      }
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      console.log(
        "SVAE RESULT",
        JSON.stringify({ customer: config.customerName, apiData: apiData })
      );
      const result = await axios.post(
        `${netlifyBaseURL}/.netlify/functions/save-result`,
        JSON.stringify({ customer: config.customerName, apiData: apiData })
      );
      console.log("🚀 ~ file: DocumentService.ts:319 ~ DocumentService ~ result:", result);
      // const result = await axios.post(`${PATH}/`, apiData, {
      //   headers: { [`${headerKey}`]: API_KEY },
      // });
      console.log("sendResult", { status: result.status });
      return result;
    } catch (err) {
      console.error("sendResult", err.message, { err });
      throw new Error(err);
    }
  }

  static async sendEmailPdf(fields: any, config: TestConfiguration, uuid: string) {
    let PATH = config.REPORT_API_URL;
    const API_KEY = config.API_KEY;
    if (!PATH) {
      throw new Error("Missing config API_URL");
    }
    if (!API_KEY) {
      throw new Error("Missing config API_KEY");
    }
    if (!uuid) {
      throw new Error("Missing UUID.");
    }
    PATH = PATH.replace("{id}", uuid);

    try {
      let fileName = `netwrok-test-highlights-${uuid}.pdf`;
      if (config.theme === "livevox") {
        fileName = await this.getFileName({
          testRunId: uuid,
          email: fields["email"],
          title: fields["title"],
        });
      }
      const headerKey = dev ? "x-consumer-username" : "apikey";
      const file = await this.getPdf(fileName);
      await axios.post(
        `${PATH}`,
        { email: fields["email"], file, fileName },
        { headers: { [`${headerKey}`]: API_KEY } }
      );

      return true;
    } catch (err) {
      console.error("sendResult", err.message, { err });
      throw new Error(err);
    }
  }

  static async downloadReport(uuid: string, config: TestConfiguration) {
    try {
      const PATH = config.CredentialsApiUrl;
      const result = await axios.get(`${PATH}/convertToPdfApiToken`);
      const token = result.data.token;

      const filename = `network-test-${uuid}`;
      const url =
        `https://v2.convertapi.com/convert/web/to/pdf?Token=${token.Id}&download=attachment&ConversionDelay=8&filename=${filename}&url=` +
        encodeURI(`${window.location.origin}/${uuid}?report=pdf`);
      // encodeURI(`https://staging-networktest.testrtc.com/5f6b3132904db319f7254076?report=pdf`);

      window.open(url, "_blank");

      return;

      // const res = await axios.get(url);
      // const data = res.data;
      // const file = new Blob([data]);

      // const windowUrl = window.URL || window.webkitURL;
      // var fileURL = windowUrl.createObjectURL(file);
      // var a = document.createElement("a");
      // a.href = fileURL;
      // a.download = filename;
      // a.target = "_self";
      // a.click();
      // windowUrl.revokeObjectURL(fileURL);
    } catch (err) {
      console.log(err, err.message, err.stack);
      alert("Could not download report. Contact support");
    }
  }
}
