import React from "react";
import { ITestsResult, IThroughputTest } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import WidgetHeader from "./UI/WidgetHeader";
import { withTheme } from "@material-ui/core";
import Bar from "./UI/Bar";
import ThroughputRow from "./UI/ThroughputRow";
import ErrorState from "./UI/ErrorState";

interface IThroughputTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config: any;
}

const initialValues: IThroughputTest = {
  averageBandwidth: undefined,
  concurrentConnections: undefined,
  highestBandwidth: undefined,
  lowestBandwidth: undefined,
  maxThroughputs: undefined,
  minThroughputs: undefined,
};

const NewThroughputTest = (props: IThroughputTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    testName,
    config,
  } = props;
  const throughputTest =
    (testResult && (testResult as any)?.bandwidthTest) || testResult?.throughput || initialValues;
  const currentThresholds = colorThresholds?.Throughput || null;
  const {
    error,
    averageBandwidth,
    concurrentConnections,
    highestBandwidth,
    lowestBandwidth,
    maxThroughputs,
    minThroughputs,
  } = throughputTest;

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };
  const showSessions = config.Throughput?.codec?.toLowerCase() !== "none";
  const formatting = !showSessions
    ? (value: number | string) => `${formatNumber(Number(value))} <span>kbps</span>`
    : undefined;

  const getColor = (value: number, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
          warningValueMax: threshold.warningMax,
          warningValueMin: threshold.warningMin,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const { t } = useTranslation(["common", "tests"]);

  return (
    <>
      <WidgetHeader
        title={t("tests:throughput.title")}
        tooltip={t("tests:throughput.tooltip")}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        badge={testResult?.testCall?.transportProtocol}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <div className="widget-throughput-wrapper">
          <div className="widget-throughput-bar-wrapper">
            <div className="widget-throughput-bar-container">
              <Bar
                value={lowestBandwidth}
                max={highestBandwidth}
                color={getColor(lowestBandwidth, currentThresholds?.lowestBandwidth)}
              />
              <Bar
                value={averageBandwidth}
                max={highestBandwidth}
                color={getColor(averageBandwidth, currentThresholds?.averageBandwidth)}
              />
              <Bar
                value={highestBandwidth}
                max={highestBandwidth}
                color={getColor(highestBandwidth, currentThresholds?.highestBandwidth)}
              />
            </div>
          </div>
          <div className="widget-throughput-content-wrapper">
            <div className="widget-throughput-content-container">
              <ThroughputRow
                label={t("minimum")}
                value={lowestBandwidth}
                color={getColor(lowestBandwidth, currentThresholds?.lowestBandwidth)}
                sessionValue={minThroughputs}
                sessionColor={getColor(minThroughputs, currentThresholds?.minThroughputs)}
                parentId="TT-r-minimum"
                valueId="TT-c-lowestBandwidth"
                sessionId="TT-c-minThroughputs"
              />
              <ThroughputRow
                label={t("average")}
                value={averageBandwidth}
                color={getColor(averageBandwidth, currentThresholds?.averageBandwidth)}
                sessionValue={concurrentConnections}
                sessionColor={getColor(
                  concurrentConnections,
                  currentThresholds?.concurrentConnections
                )}
                parentId="TT-r-average"
                valueId="TT-c-averageBandwidth"
                sessionId="TT-c-concurrentConnections"
              />
              <ThroughputRow
                label={t("maximum")}
                value={highestBandwidth}
                color={getColor(highestBandwidth, currentThresholds?.highestBandwidth)}
                sessionValue={maxThroughputs}
                sessionColor={getColor(maxThroughputs, currentThresholds?.maxThroughputs)}
                parentId="TT-r-highestBandwidth"
                valueId="TT-c-highestBandwidth"
                sessionId="TT-c-maxThroughputs"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTheme()(NewThroughputTest);
